import { Configuration } from "./config";
import { Organization } from "./org";

export interface User {
  id: string
  configuration: Configuration
  createdAt: string
  email: string
  firstName: string
  lastName: string
  isLoading: boolean
  nextOnboardingStep: OnboardingStep
  onboardingStep: OnboardingStep
  organization: Organization
  personas: Persona[]
  remainingBlueprintCredits: number
  role: string
}

export enum OnboardingStep {
  BILLING = "BILLING",
  COMPLETE = "COMPLETE",
  IDENTIFICATION = "IDENTIFICATION",
  LOCATION = "LOCATION",
  VEHICLE = "VEHICLE",
}

export enum UserRole {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  STAFF = "STAFF",
}

export enum Persona {
  INDUSTRIAL_DESIGNER = "INDUSTRIAL_DESIGNER",
  INTERIOR_DESIGNER = "INTERIOR_DESIGNER",
  MAKER = "MAKER",
}
