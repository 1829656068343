// Image component to show the watermarked URL or an optimized version of the original.

import React, { MouseEventHandler, useMemo } from "react";
import { PhotoSynth } from "photosynth-react";
import { Image } from "../models/image";

export interface FurnImageArgs {
  height?: string | number
  image?: Image
  onClick?: MouseEventHandler<HTMLImageElement>
  style?: React.CSSProperties
  width?: string | number
}

export function FurnImage(args: FurnImageArgs) {
  const { height, image, onClick, style, width } = args;

  return useMemo(() => {
    if (!image) return <></>;
    const { url, hasWatermark } = image;
    if (hasWatermark) {
      // Watermarked images have already been sized down. No optimization needed.
      return (
        <img
          alt={image.name}
          height={height}
          key={image.id}
          onClick={onClick}
          src={url}
          srcSet={url}
          style={style}
          width={width}
        />
      );
    }

    // Original non-watermarked images are large and need to be optimized.
    return (
      <PhotoSynth
        cssStyle={style}
        onClick={onClick}
        sourceUrl={url}
        {...(typeof width === "number" ? { width } : undefined)}
      />
    );
  }, [height, image, onClick, style, width]);
}
