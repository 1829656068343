// Image Gallery with infinite scrolling

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import ImageList from "@mui/material/ImageList";
import { Typography } from "@mui/joy";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import ReplayIcon from "@mui/icons-material/Replay";
import { GET_IMAGES_AI_CONNECTION, clearImagesCache, useImagesConnection } from "../graphql/image";
import { apolloClient } from "../graphql/apolloClient";
import { ImageAi } from "./imageAi";

export interface ImageGalleryArgs {
  favorite?: boolean
  generator?: "AI"
  mode?: "regular" | "trash"
  offsetIncrement?: number // # pixels above the bottom list element where the buffer increments
  pageSize?: number // number of items to render at start
  subtitle?: string
  title?: string
}

export function ImageGallery(props: ImageGalleryArgs) {
  const {
    favorite,
    generator,
    mode = "regular",
    offsetIncrement = 80,
    pageSize = 12,
    subtitle,
    title,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [before, setBefore] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isReloading, setIsReloading] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.down("xl"));

  const {
    allImages, loading, edges, refetch, startCursor, totalCount,
  } = useImagesConnection({
    before,
    deleted: mode === "trash",
    favorite,
    last: pageSize,
    generator,
    queryDoc: GET_IMAGES_AI_CONNECTION,
  });

  const isBusy = useMemo(() => isReloading || loading, [loading, isReloading]);

  const refetchClean = useCallback(async () => {
    setBefore(undefined);
    setIsReloading(true);
    await clearImagesCache(apolloClient);
    const { loading: refetching } = await refetch();
    setIsReloading(refetching);
  }, [refetch]);

  const imageItems = useMemo(() => {
    if ((allImages.length === 0) && (mode === "regular") && !loading) {
      return (
        <Box style={loadingContainerStyle}>
          <Typography level="title-md">
            {favorite ? "Favorite" : "Custom"} furniture will appear here
          </Typography>
        </Box>
      );
    }

    let cols = 4;
    if (isXSmall) {
      cols = 1;
    }
    else if (isSmall) {
      cols = 2;
    }
    else if (isLarge) {
      cols = 3;
    }
    return (
      <ImageList cols={cols} gap={8} style={imageListStyle}>
        {allImages.map(img => (
          <ImageAi image={img} mode={mode} refetch={refetchClean} key={img.id} />
        ))}
      </ImageList>
    );
  }, [allImages, favorite, isLarge, isSmall, isXSmall, loading, mode, refetchClean]);

  useEffect(() => {
    // Reset `before` arg if images were cleared (e.g. after mutation)
    if (!allImages.length) {
      setBefore(undefined);
    }
  }, [allImages]);

  const scrollDebounce = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollDebounce.current) {
        // Debounce prevents double firing page requests
        clearTimeout(scrollDebounce.current);
        scrollDebounce.current = undefined;
      }
      scrollDebounce.current = setTimeout(() => {
        if (
          (scrollRef.current?.offsetTop || Number.POSITIVE_INFINITY)
          <= window.innerHeight
          + document.documentElement.scrollTop
          + offsetIncrement
          && allImages.length < totalCount
        ) {
          setBefore(startCursor);
        }
      }, 100);
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup
    return () => window.removeEventListener("scroll", handleScroll);
  }, [allImages.length, edges, offsetIncrement, startCursor, totalCount]);

  return (
    <Box>
      <Box sx={titleContainerStyle}>
        <Box>
          <Typography level="h2" style={titleStyle} color="primary">{title}</Typography>
          <Typography level="body-sm">{subtitle}</Typography>
        </Box>
        <IconButton onClick={refetchClean} variant="plain">
          <ReplayIcon />
        </IconButton>
      </Box>
      {imageItems}

      {isBusy && (
        <Box style={loadingContainerStyle}>
          <CircularProgress size="lg" />
        </Box>
      )}
      <div ref={scrollRef} />
    </Box>
  );
}

const loadingContainerStyle = {
  display: "flex",
  justifyContent: "center",
  margin: "4rem",
};

const titleContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const titleStyle = {
  marginTop: "2rem",
  marginBottom: "1rem",
};

const imageListStyle = {
  overflow: "hidden",
};
