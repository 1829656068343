// An AI image card that shows generated images. Images that are processing are polled until
// the image is finalized.

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Modal from "@mui/joy/Modal";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SellIcon from "@mui/icons-material/Sell";
import UndoIcon from "@mui/icons-material/Undo";
import WarningIcon from "@mui/icons-material/Warning";
import { Image } from "../models/image";
import {
  clearImagesCache,
  getImages,
  getImagesWithBlueprints,
  useDeleteImages,
  useUndeleteImages,
  useUpdateImages,
} from "../graphql/image";
import { apolloClient } from "../graphql/apolloClient";
import { CopyIcon } from "./copyIcon";
import { useAiGeneratorTabs } from "../hooks/useAiGeneratorTabs";
import { routes } from "../pages/routes";
import { FurnImage } from "./furnImage";

export interface ImageAiArgs {
  enableDelete?: boolean
  image: Image
  mode?: "regular" | "trash"
  refetch?: Function
  setIsUpdating?: Function
}

export function ImageAi(args: ImageAiArgs) {
  const {
    enableDelete, image: propImage, mode = "regular", refetch, setIsUpdating,
  } = args;
  const [image, setImage] = useState(propImage);
  const [imageInProgress, setImageInProgress] = useState(false);
  const [imageError, setImageError] = useState("");
  const [fullSizeOpen, setFullSizeOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showUndeleteAlert, setShowUndeleteAlert] = useState(false);
  const [actionError, setActionError] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [deleteImages, { loading: isDeleting }] = useDeleteImages();
  const [undeleteImages, { loading: isUndeleting }] = useUndeleteImages();
  const [updateImages] = useUpdateImages();
  const { generatorValues: genVals, setGeneratorValues } = useAiGeneratorTabs();
  const navigate = useNavigate();
  const imageUrl = image?.url;
  const isBusy = isDeleting || isUndeleting || imageInProgress;

  // Query image for updates if generation is in progress.
  useEffect(() => {
    if (imageUrl) {
      return;
    }
    if (image?.generator?.status === "ERROR") {
      setImageError("Error generating image");
      return;
    }
    let imagePoll: NodeJS.Timer;
    async function updateImage() {
      if (imagePoll) {
        clearInterval(imagePoll);
      }
      if (!imageUrl && image.id) {
        // Image is generating. Poll for updates.
        setImageInProgress(true);
        setIsUpdating?.(true);
        imagePoll = setInterval(async () => {
          const { images /* errors */ } = await getImages({
            client: apolloClient,
            ids: [image.id],
            fetchPolicy: "network-only",
          });
          const updatedImage = images[0];
          setImage(updatedImage);
          if (updatedImage?.url) {
            clearInterval(imagePoll);
            setImageInProgress(false);
            setIsUpdating?.(false);
            await clearImagesCache(apolloClient);
            refetch?.();
          }
          if (updatedImage?.generator?.status === "ERROR") {
            clearInterval(imagePoll);
            setImageInProgress(false);
            setIsUpdating?.(false);
          }
        }, 5000);
      }
    }
    updateImage();
  }, [image?.generator?.status, image?.id, imageUrl, refetch, setIsUpdating]);

  const deleteValues = useMemo(() => {
    let deleteAlertColor;
    let deleteMessage;
    let deleteTitle;
    let restoreButton;

    switch (mode) {
      case "trash":
        deleteAlertColor = "danger";
        deleteMessage = "Image will be permanently deleted. No recovery is available.";
        deleteTitle = "Delete Image?";
        restoreButton = (
          <IconButton
            aria-label={`restore ${image.name}`}
            disabled={isBusy}
            onClick={() => setShowUndeleteAlert(true)}
            style={iconButtonStyle}
          >
            <UndoIcon />
          </IconButton>
        );
        break;
      default:
        deleteAlertColor = "neutral";
        deleteMessage = "Image may be retrieved for 30 days";
        deleteTitle = "Move Image to Trash?";
        break;
    }
    return { deleteAlertColor, deleteMessage, deleteTitle, restoreButton };
  }, [image?.name, isBusy, mode]);

  const { deleteAlertColor, deleteMessage, deleteTitle, restoreButton } = deleteValues;

  const deleteHandler = useCallback(async () => {
    try {
      await deleteImages({ variables: { input: { ids: [image.id], removeFiles: mode === "trash" } } });
      await clearImagesCache(apolloClient);
      refetch?.();
      setFullSizeOpen(false);
    }
    catch (err: any) {
      setShowErrorAlert(true);
      setActionError(err?.message ?? "Unknown error");
    }
    setShowDeleteAlert(false);
  }, [deleteImages, image?.id, mode, refetch]);

  const abortDeleteHandler = useCallback(() => {
    setShowDeleteAlert(false);
  }, []);

  const undeleteHandler = useCallback(async () => {
    await undeleteImages({ variables: { input: { ids: [image.id] } } });
    await clearImagesCache(apolloClient);
    refetch?.();
    setShowUndeleteAlert(false);
    setFullSizeOpen(false);
  }, [image?.id, refetch, undeleteImages]);

  const abortUndeleteHandler = useCallback(() => {
    setShowUndeleteAlert(false);
  }, []);

  const closeErrorHandler = useCallback(() => {
    setShowErrorAlert(false);
  }, []);

  const toggleFavoriteHandler = useCallback(async () => {
    await updateImages({ variables: { input: { images: [{ id: image.id }], favorite: !image.favorite } } });
    await clearImagesCache(apolloClient);
    refetch?.();
  }, [image.favorite, image.id, refetch, updateImages]);

  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const infoHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setInfoAnchorEl(event.currentTarget);
  };
  const infoOpen = Boolean(infoAnchorEl);

  const closeInfo = () => {
    setInfoAnchorEl(null);
  };

  const regenerateHandler = useCallback(() => {
    setFullSizeOpen(false);
    const { generator, height, name, width } = image ?? {};
    const { mode: _mode, model, negativePrompt, prompt, seed } = generator ?? {};
    const isRegenerate = true;
    setGeneratorValues({
      ...genVals, height, isRegenerate, mode: _mode, model, name, negativePrompt, prompt, seed, width,
    });
  }, [image, setGeneratorValues, genVals]);

  const { optimizedImg, origImg } = useMemo(() => {
    if (imageInProgress) {
      const progressThumb = image?.generator?.progressThumb;
      return {
        optimizedImg: (
          <Box style={centerContentsStyle} >
            <CircularProgress size="lg" sx={spinnerStyle} />
            {progressThumb
              ? <img src={`data:image/png;base64, ${progressThumb}`} width="100%" alt="In progress" />
              : <Box sx={spinnerTextContainerStyle}>
                <Typography>Image generation may take up to 30 seconds...</Typography>
              </Box>
            }
          </Box>
        ),
        origImg: null,
      };
    }
    if (imageError) {
      return {
        optimizedImg: (
          <Box style={centerContentsStyle} >
            <Typography>
              {imageError}
            </Typography>
          </Box>
        ),
        origImg: null,
      };
    }

    return {
      optimizedImg: (
        <FurnImage
          height="100%"
          image={image}
          onClick={() => setFullSizeOpen(true)}
          width="100%"
        />
      ),
      origImg: (
        <img
          alt={""}
          height="100%"
          loading="lazy"
          srcSet={`${imageUrl}`}
          src={`${imageUrl}`}
          width="100%"
        />
      ),
    };
  }, [image, imageUrl, imageError, imageInProgress]);

  const [imgMenuAnchorEl, setImgMenuAnchorEl] = useState<null | HTMLElement>(null);
  const imgMenuOpen = Boolean(imgMenuAnchorEl);
  const handleImgMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setImgMenuAnchorEl(event.currentTarget);
  };
  const handleImgMenuClose = () => {
    setImgMenuAnchorEl(null);
  };

  const imageMenuItems = useMemo(() => {
    const onOrderBlueprint = async () => {
      handleImgMenuClose();
      // Check if blueprint was already purchased.
      const { images } = await getImagesWithBlueprints({ client: apolloClient, ids: [image.id] });
      const blueprint = images[0]?.blueprints?.[0];
      if (blueprint?.id) {
        navigate(`${routes.blueprint.route}/${blueprint.id}`);
      }
      else {
        navigate(`${routes.blueprintOrder.route}/${image.id}`);
      }
    };
    return (
      <Box>
        <MenuItem onClick={handleImgMenuClose}>
          <IconButton
            aria-label={`info ${image.name}`}
            onClick={infoHandler}
          >
            <InfoIcon />
            Image Info
          </IconButton>
        </MenuItem>
        <MenuItem onClick={handleImgMenuClose}>
          <IconButton onClick={toggleFavoriteHandler}>
            {image?.favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            Favorite
          </IconButton>
        </MenuItem>
        <MenuItem onClick={onOrderBlueprint}>
          <IconButton >
            <SellIcon />
            Order Blueprint
          </IconButton>
        </MenuItem>
      </Box>
    );
  }, [image?.favorite, image.id, image.name, navigate, toggleFavoriteHandler]);

  const imageDetails = useMemo(() => {
    const { generator, name, url } = image;
    const { prompt } = generator;
    return (
      <Sheet variant="outlined">
        <Box sx={ImageDetailsContainer}>
          <Typography level="body-lg" fontWeight="lg" my={0.5}>
            Name: {name}
          </Typography>
          <Typography level="body-lg" fontWeight="lg" my={0.5}>
            Prompt: {prompt}
          </Typography>
          {url
            && <Box style={imageUrlContainerStyle}>
              <Typography level="body-lg" fontWeight="lg" my={0.5}>
                {url}
              </Typography>
              <CopyIcon value={url} />
            </Box>
          }
        </Box>
      </Sheet>
    );
  }, [image]);

  return (
    <ImageListItem>
      {!isBusy
        && <ImageListItemBar
          actionIcon={
            <IconButton
              aria-label={`info ${image.name}`}
              disabled={isBusy}
              onClick={handleImgMenuClick}
              style={iconButtonStyle}
              variant="solid"
            >
              <MoreHorizIcon />
            </IconButton>
          }
          actionPosition="right"
          // onClick={infoHandler}
          position="top"
          style={imageListItemBarStyle}
          title={image.name}
        />
      }
      <Menu
        anchorEl={imgMenuAnchorEl}
        id="image-menu"
        open={imgMenuOpen}
        onClose={handleImgMenuClose}
        onClick={handleImgMenuClose}
        slotProps={imageMenuStyleProps}
        transformOrigin={imageMenuTransformOrigin}
        anchorOrigin={imageMenuAnchorOrigin}
      >
        {imageMenuItems}
      </Menu>
      <Popover
        anchorEl={infoAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={infoOpen}
        onClose={closeInfo}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{ paper: { style: { opacity: "0.9" } } }}
      >
        {imageDetails}
      </Popover>

      {optimizedImg}

      {/* Full size image modal */}
      <Modal
        aria-labelledby="original-image"
        open={fullSizeOpen}
        onClose={() => setFullSizeOpen(false)}
        style={centerContentsStyle}
      >
        <Sheet
          variant="outlined"
          sx={modalSheetStyle}
        >
          <Box sx={modalButtonContainerStyle} >
            {enableDelete
              && <>
              <Box sx={modalInfoContainerStyle} >
                <Tooltip title="Regenerate image">
                  <IconButton
                    color="neutral"
                    disabled={isBusy}
                    onClick={regenerateHandler}
                    variant="plain"
                    size="sm"
                    style={iconButtonStyle}
                  >
                    <PostAddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
                {restoreButton}
                <IconButton
                  aria-label={`delete ${image.name}`}
                  color="neutral"
                  disabled={isBusy}
                  onClick={() => setShowDeleteAlert(true)}
                  style={iconButtonStyle}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
            <IconButton
              aria-label={`close ${image.name}`}
              color="neutral"
              disabled={isBusy}
              onClick={() => setFullSizeOpen(false)}
              style={closeIconButtonStyle}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={modalImgStyle}>
            {origImg}
          </Box>
        </Sheet>
      </Modal>

      {/* Delete modal */}
      <Modal open={showDeleteAlert} style={centerContentsStyle}>
        <Alert
          variant="soft"
          color={deleteAlertColor as any}
          invertedColors
          startDecorator={<WarningIcon />}
          style={deleteAlertStyle}
        >
          <Box style={deleteBoxStyle}>
            <Typography level="title-md">{deleteTitle}</Typography>
            <Typography level="body-md">{deleteMessage}</Typography>
            <Box style={deleteButtonsContainerStyle}>
              <Button onClick={abortDeleteHandler} variant="outlined" size="sm" disabled={isBusy}>
                Cancel
              </Button>
              <Button onClick={deleteHandler} variant="solid" size="sm" disabled={isBusy}>
                Delete
              </Button>
            </Box>
          </Box>
        </Alert>
      </Modal>

      {/* Restore modal */}
      <Modal open={showUndeleteAlert} style={centerContentsStyle}>
        <Alert
          variant="soft"
          invertedColors
          style={deleteAlertStyle}
        >
          <Box style={deleteBoxStyle}>
            <Typography level="title-md">Restore Image?</Typography>
            <Typography level="body-md">Image will be moved to the active list</Typography>
            <Box style={deleteButtonsContainerStyle}>
              <Button onClick={abortUndeleteHandler} variant="outlined" size="sm" disabled={isBusy}>
                Cancel
              </Button>
              <Button onClick={undeleteHandler} variant="solid" size="sm" disabled={isBusy}>
                Restore
              </Button>
            </Box>
          </Box>
        </Alert>
      </Modal>

      {/* Error modal */}
      <Modal open={showErrorAlert} style={centerContentsStyle}>
        <Alert
          variant="soft"
          invertedColors
          style={deleteAlertStyle}
        >
          <Box style={deleteBoxStyle}>
            <Typography level="title-md">Error</Typography>
            <Typography level="body-md">{actionError}</Typography>
            <Box style={deleteButtonsContainerStyle}>
              <Button onClick={closeErrorHandler} variant="outlined" size="sm" disabled={isBusy}>
                Close
              </Button>
            </Box>
          </Box>
        </Alert>
      </Modal>
    </ImageListItem>
  );
}

const iconButtonStyle = {
  marginRight: ".75rem",
};

const imageListItemBarStyle = {
  background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
};

const imageMenuStyleProps = {
  paper: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&::before": {
        // eslint-disable-next-line quotes
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
};

const imageMenuTransformOrigin = {
  horizontal: "right" as any,
  vertical: "top" as any,
};

const imageMenuAnchorOrigin = {
  horizontal: "right" as any,
  vertical: "bottom" as any,
};

const ImageDetailsContainer = {
  margin: "1rem",
};

const centerContentsStyle = {
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  width: "100%",
};

const modalButtonContainerStyle = {
  background:
    "linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.5) 70%, rgba(255,255,255,0) 100%)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  left: "0%",
  padding: "0.25rem",
  position: "absolute",
  top: "0%",
  width: "100%",
};

const modalInfoContainerStyle = {
  // justifyContent: "flex-end",
  // display: "flex",
  // flexDirection: "row",
  flex: 1,
};

const modalSheetStyle = {
  width: "97%",
  height: "97%",
  borderRadius: "md",
  padding: "2px",
  boxShadow: "lg",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const modalImgStyle = {
  maxHeight: "100%",
  maxWidth: "100%",
  overflow: "hidden",
};

const closeIconButtonStyle = {
};

const deleteAlertStyle = {
  alignItems: "flex-start",
  gap: "1rem",
};

const deleteBoxStyle = {
  flex: 1,
};

const deleteButtonsContainerStyle = {
  marginTop: "2rem",
  display: "flex",
  justifyContent: "flex-end",
  gap: "1rem",
};

const imageUrlContainerStyle = {
  display: "flex",
};

const spinnerStyle = {
  position: "absolute",
  left: "50%",
  opacity: 0.5,
  top: "50%",
  transform: "translateX(-50%) translateY(-50%)",
};

const spinnerTextContainerStyle = {
  mt: "6rem",
};
