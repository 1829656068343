import { useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AI } from "./ai";
// import { Billing } from "./billing";
import { Blueprint } from "./blueprint";
import { BlueprintList } from "../components/blueprintList";
import { BookDemo } from "./bookDemo";
import { Home } from "./home";
import { ImageGallery } from "../components/imageGallery";
import { Profile } from "./profile";
import { routes } from "./routes";
import { useUser } from "../graphql/user";

export function Pages() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { redirectDemo, redirectHome } = useMemo(() => {
    if (user?.isLoading) {
      // User is loading. Don't navigate yet.
      return { redirectDemo: false, redirectHome: false };
    }
    // Redirect to BookDemo for inactive subs
    const isInactiveSub = !user?.configuration?.subscription?.isActiveSubscription
      && location.pathname !== routes.bookDemo.route;
    // Redirect to Home for active subs only from BookDemo page. All other routes remain as-is.
    const isActiveSub = user?.configuration?.subscription?.isActiveSubscription
      && location.pathname === routes.bookDemo.route;
    return { redirectDemo: isInactiveSub, redirectHome: isActiveSub };
  }, [location.pathname, user?.configuration, user?.isLoading]);

  useEffect(() => {
    if (redirectDemo) {
      navigate(routes.bookDemo.route);
    }
    else if (redirectHome) {
      navigate(routes.home.route);
    }
  }, [navigate, redirectDemo, redirectHome]);

  return (
    <div style={routeContainerStyle}>
      <Routes>
        <Route path={routes.aiGenerate.route} element={<AI />} />
        <Route
          path={routes.aiImages.route}
          element={<ImageGallery title="Generated Furniture" generator="AI" />}
        />
        <Route
          path={routes.aiImagesFav.route}
          element={
            <ImageGallery
              title="Favorite Furniture"
              generator="AI"
              favorite={true}
            />
          }
        />
        <Route path={routes.blueprintList.route} element={<BlueprintList title="Blueprint List" />} />
        {/* <Route path={routes.billing.route} element={<Billing title="Subscription"/>} /> */}
        <Route path={`${routes.blueprintOrder.route}/:id`} element={<Blueprint create={true} />} />
        <Route path={`${routes.blueprint.route}/:id`} element={<Blueprint />} />
        <Route path={routes.home.route} element={<Home />} />
        <Route path={routes.profile.route} element={<Profile />} />
        <Route path={routes.bookDemo.route} element={<BookDemo />} />
      </Routes>
    </div>
  );
}

const routeContainerStyle = {
  margin: "4rem 1rem 1rem 1rem",
};
