/* eslint-disable indent */
import { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/joy/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import IconButton from "@mui/joy/IconButton";
import SellIcon from "@mui/icons-material/Sell";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { format, toDate } from "date-fns";
import { Checkout } from "./checkout";
import {
  useBlueprints, useDeleteBlueprintFiles, useFurnitureDesignSpecs, useUploadBlueprintFile,
} from "../graphql/blueprint";
import { useCreateCheckoutSession } from "../graphql/billing";
import { useUser } from "../graphql/user";
import { FurnitureControlType } from "../models/blueprint";
import { Persona } from "../models/user";
import { FileInput } from "./fileInput";
import { FurnDialog } from "./furnDialog";
import { FurnImage } from "./furnImage";

export interface BlueprintViewArgs {
  id: string
}

export function BlueprintView(props: BlueprintViewArgs) {
  const { id } = props;
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [createCheckoutSession, { data, loading: isCheckout }] = useCreateCheckoutSession();
  const { clientSecret, id: sessionId, isCredit = null } = data?.createCheckoutSession ?? {};
  const { user } = useUser(null, "network-only");
  const [uploadBlueprintFile, { error: uploadError }] = useUploadBlueprintFile();
  const [deleteBlueprintFiles] = useDeleteBlueprintFiles();
  const [file, setFile] = useState<File | null>();
  const [fileDescription, setFileDescription] = useState<string | null>();
  const [isActive, setIsActive] = useState(false);
  const {
    furnitureDesignSpecs, loading, // error, // ToDo: error handling
  } = useFurnitureDesignSpecs({ fetchPolicy: "network-only" });

  const {
    blueprints, loading: bpLoading, refetch, // error, // ToDo: error handling
  } = useBlueprints({ ids: [id] });
  const blueprint = blueprints?.[0];

  const isBusy = useMemo(() => bpLoading || loading || isActive || isCheckout || isPurchaseOpen,
    [bpLoading, isActive, isCheckout, isPurchaseOpen, loading],
  );

  const apiRef = useGridApiRef();
  const { images } = blueprints[0] || {};
  const columns = [
    { field: "name", headerName: "Blueprint", minWidth: 200 },
    { field: "value", headerName: "", minWidth: 300 },
  ];
  const rows = useMemo(() => {
    if (!blueprint) return [];
    const furnitureFields = furnitureDesignSpecs.find(f => f.type === blueprint.type)?.fields ?? [];
    return furnitureFields.map(ff => {
      const { label, name, type } = ff;
      let value = (blueprint as any)[name];
      if (type === "DATE" as FurnitureControlType && value) {
        value = format(toDate(value), "MM-dd-yyyy");
      }
      return {
        id: name,
        name: label,
        value,
      };
    });
  }, [blueprint, furnitureDesignSpecs]);

  const blueprintImages = useMemo(() =>
    images?.map(img => <FurnImage image={img} />),
  [images]);

  const handleSubmit = useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!file) {
      return;
    }
    setIsActive(true);
    const { name: filename, type } = file;
    const response = await uploadBlueprintFile({
      variables: {
        input: {
          blueprintId: blueprint.id,
          description: fileDescription,
          filename,
          type,
        },
      },
    });
    const { uploadUrl /* url */ } = response.data?.uploadBlueprintFile ?? {};
    try {
      if (!uploadUrl || uploadError) {
        throw new Error("Invalid uploadUrl");
      }
      await fetch(uploadUrl, {
        method: "PUT",
        body: file,
      });
    }
    catch (e) {
      // ToDo: show error
      setIsActive(false);
      return;
    }

    setFile(undefined);
    setFileDescription(undefined);
    setIsActive(false);
  }, [blueprint?.id, file, fileDescription, uploadBlueprintFile, uploadError]);

  const uploader = useMemo(() => {
    if (!user.personas?.includes(Persona.INDUSTRIAL_DESIGNER)) {
      return null;
    }
    return (
      <Card style={docCardStyle}>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FileInput
              disabled={isBusy}
              label="Upload blueprint document"
              onFileSelect={(selectedFile: SetStateAction<File>) => setFile(selectedFile as File)}
              required={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Notes
            </FormLabel>
            <Textarea
              disabled={isBusy}
              onBlur={(e: any) => setFileDescription(fileDescription?.trim())}
              onChange={(e: any) => setFileDescription(e?.target?.value)}
              value={fileDescription ?? undefined}
            />
          </FormControl>
          <Button type="submit" style={submitButtonStyle} disabled={isBusy}>
            Upload
          </Button>
        </form>
      </Card>
    );
  }, [fileDescription, handleSubmit, isBusy, user.personas]);

  const handleOrderBlueprint = useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (blueprint && !blueprint.hasPurchased) {
      setIsActive(true);
      // checkout response contains either sessionId or isCredit.
      await createCheckoutSession({
        variables: { input: { blueprintId: blueprint.id } },
      });
    }
  }, [blueprint, createCheckoutSession]);

  const orderPurchaseButton = useMemo(() => {
    if (blueprint?.hasPurchased) {
      return (
        <Typography style={paidStyle} fontSize="md" color="success">
          <CheckIcon style={checkIconStyle} />
          Paid
        </Typography>
      );
    }
    // Purchase button
    return (
      <Box sx={purchaseContainerStyle}>
        <Button onClick={handleOrderBlueprint} style={purchaseButtonStyle} disabled={isBusy}>
          <SellIcon sx={sellIconStyle}/>
          Purchase Blueprint
        </Button>
          <Typography>Remaining Credits: {user?.remainingBlueprintCredits}</Typography>
      </Box>
    );
  }, [blueprint?.hasPurchased, handleOrderBlueprint, isBusy, user?.remainingBlueprintCredits]);

  const onDeleteOpen = useCallback(async (fid: string) => {
    setIdsToDelete([fid]);
  }, []);

  const onDeleteCancel = useCallback(() => {
    setIdsToDelete([]);
  }, []);

  const handleDeleteFile = useCallback(async () => {
    // event.preventDefault();
    setIsActive(true);
    await deleteBlueprintFiles({ variables: { input: { ids: idsToDelete } } });
    await refetch();
    setIdsToDelete([]);
    setIsActive(false);
  }, [deleteBlueprintFiles, idsToDelete, refetch]);

  const documents = useMemo(() => {
    if (!blueprint?.files?.length) return null;
    const docs = blueprint.files.map(f => (
      <Box key={f.id} sx={rowContainerStyle}>
        <IconButton
          aria-label={`download ${f.filename}`}
          component="a"
          download={f.filename}
          href={f.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          <DownloadIcon />
        </IconButton>
        <Box>
          <Typography>
          {f.filename} - {format(f.updatedAt, "MM-dd-yyyy")}
          </Typography>
          <Typography>{f.description}</Typography>
        </Box>
        { user.personas?.includes(Persona.INDUSTRIAL_DESIGNER) && (
          <IconButton
            aria-label={`delete ${f.filename}`}
            onClick={() => onDeleteOpen(f.id)}
            style={deleteButtonStyle}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    ));
    return (
      <Card style={docCardStyle}>
        <Typography level="title-md">Available Documents</Typography>
        {docs}
      </Card>
    );
  }, [blueprint?.files, onDeleteOpen, user.personas]);

  useEffect(() => {
    if (sessionId) {
      setIsPurchaseOpen(true);
      setIsActive(false);
    }
    else if (isCredit) {
      setIsActive(false); // No checkout necessary for credit blueprint
    }
  }, [isCredit, sessionId]);

  const purchaseBox = useMemo(() => (
    <Checkout
      clientSecret={clientSecret}
      onOpenClose={isOpen => setIsPurchaseOpen(isOpen)}
      sessionId={sessionId}
    />
  ), [clientSecret, sessionId]);

  if (isPurchaseOpen) {
    // Purchase mode displays Stripe checkout page
    return (
      <Box>
        {purchaseBox}
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2} >
        <Grid xs={12} lg={8}>
          {orderPurchaseButton}
          {uploader}
          {documents}
          { blueprint?.id && (
            <DataGrid
              apiRef={apiRef}
              autosizeOnMount
              columns={columns}
              // pagination={false}
              hideFooter
              rows={rows}
            />
          )}
        </Grid>
        <Grid xs={12} lg={4} direction="row">
          {images?.length === 0
            ? (
              <Typography>No Furniture Image associated with Blueprint</Typography>
            )
            : (
              blueprintImages
            )
          }
        </Grid>
      </Grid>

      <FurnDialog
        body="Are you sure you want to delete this document?"
        isBusy={isBusy}
        isOpen={!!idsToDelete.length}
        okText="Yes"
        onClose={onDeleteCancel}
        onCancel={onDeleteCancel}
        onOk={handleDeleteFile}
        title="Confirm document deletion"
      />
      { isBusy && (
        <Box sx={loadingContainerStyle}>
          <CircularProgress size="lg" />
        </Box>
      )}
    </Box>
  );
}

const loadingContainerStyle = {
  alignItems: "center",
  backgroundColor: "white",
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  opacity: "50%",
  position: "absolute",
  top: "3rem",
  width: "100vw",
};

const sellIconStyle = {
  mr: 1,
};

const purchaseButtonStyle = {
  marginRight: "1.5rem",
};

const paidStyle = {
  marginBottom: "1rem",
};

const checkIconStyle = {
  verticalAlign: "middle",
};

const docCardStyle = {
  marginTop: "1rem",
  marginBottom: "1rem",
};

const rowContainerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
};

const purchaseContainerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  marginBottom: "1rem",
};

const submitButtonStyle = {
  marginTop: "0.5rem",
};

const deleteButtonStyle = {
  alignItems: "start",
  marginLeft: "1rem",
};
